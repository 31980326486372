import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  travels,
  travelsForm,
  expenses,
  expensesForm,
} from "./Reducers";

export default combineReducers({
  expenses,
  expensesForm,
  travels,
  travelsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
