// https://testing-core.deploy.kuarasoftware.es/

export const API = {
	expenses: {
    create: `/expense`,
    edit: `/expense/`,
    delete: `/expense/`,
    list: `/expense`,
  },
	travels: {
    create: `/travel`,
    edit: `/travel/`,
    delete: `/travel/`,
    list: `/travel`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
