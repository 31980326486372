const scheme = {
  "main": "#662880",
  "mainTrans": "#E2CBC7",
  "secondary": "#F6AF38",
  "secondaryTrans": "#E7267721",
  "light": "#fafafa",
  "dark": "#222222",
  "border": "#f0f0f0",
  "mainBlur": "#78277f26",
  'warning': "#fa541c",
  "color-primary-100": "#D6E4FF",
  "color-primary-200": "#ADC8FF",
  "color-primary-300": "#84A9FF",
  "color-primary-400": "#6690FF",
  "color-primary-500": "#3366FF",
  "color-primary-600": "#254EDB",
  "color-primary-700": "#1939B7",
  "color-primary-800": "#102693",
  "color-primary-900": "#091A7A",
  "color-primary-transparent-100": "rgba(51, 102, 255, 0.08)",
  "color-primary-transparent-200": "rgba(51, 102, 255, 0.16)",
  "color-primary-transparent-300": "rgba(51, 102, 255, 0.24)",
  "color-primary-transparent-400": "rgba(51, 102, 255, 0.32)",
  "color-primary-transparent-500": "rgba(51, 102, 255, 0.4)",
  "color-primary-transparent-600": "rgba(51, 102, 255, 0.48)",
  "color-success-100": "#ECFDD6",
  "color-success-200": "#D5FBAE",
  "color-success-300": "#B6F483",
  "color-success-400": "#97EA63",
  "color-success-500": "#69DD33",
  "color-success-600": "#4BBE25",
  "color-success-700": "#329F19",
  "color-success-800": "#1D8010",
  "color-success-900": "#0F6A09",
  "color-success-transparent-100": "rgba(105, 221, 51, 0.08)",
  "color-success-transparent-200": "rgba(105, 221, 51, 0.16)",
  "color-success-transparent-300": "rgba(105, 221, 51, 0.24)",
  "color-success-transparent-400": "rgba(105, 221, 51, 0.32)",
  "color-success-transparent-500": "rgba(105, 221, 51, 0.4)",
  "color-success-transparent-600": "rgba(105, 221, 51, 0.48)",
  "color-info-100": "#D9F9FF",
  "color-info-200": "#B4EFFF",
  "color-info-300": "#8EE1FF",
  "color-info-400": "#72D1FF",
  "color-info-500": "#44B7FF",
  "color-info-600": "#318FDB",
  "color-info-700": "#226CB7",
  "color-info-800": "#154C93",
  "color-info-900": "#0D367A",
  "color-info-transparent-100": "rgba(68, 183, 255, 0.08)",
  "color-info-transparent-200": "rgba(68, 183, 255, 0.16)",
  "color-info-transparent-300": "rgba(68, 183, 255, 0.24)",
  "color-info-transparent-400": "rgba(68, 183, 255, 0.32)",
  "color-info-transparent-500": "rgba(68, 183, 255, 0.4)",
  "color-info-transparent-600": "rgba(68, 183, 255, 0.48)",
  "color-warning-100": "#FFF5D8",
  "color-warning-200": "#FFE8B2",
  "color-warning-300": "#FFD88B",
  "color-warning-400": "#FFC96F",
  "color-warning-500": "#FFAF3F",
  "color-warning-600": "#DB8B2E",
  "color-warning-700": "#B76B1F",
  "color-warning-800": "#934E14",
  "color-warning-900": "#7A390C",
  "color-warning-transparent-100": "rgba(255, 175, 63, 0.08)",
  "color-warning-transparent-200": "rgba(255, 175, 63, 0.16)",
  "color-warning-transparent-300": "rgba(255, 175, 63, 0.24)",
  "color-warning-transparent-400": "rgba(255, 175, 63, 0.32)",
  "color-warning-transparent-500": "rgba(255, 175, 63, 0.4)",
  "color-warning-transparent-600": "rgba(255, 175, 63, 0.48)",
  "color-danger-100": "#FFE3D5",
  "color-danger-200": "#FFC1AC",
  "color-danger-300": "#FF9882",
  "color-danger-400": "#FF7163",
  "color-danger-500": "#FF3030",
  "color-danger-600": "#DB2333",
  "color-danger-700": "#B71833",
  "color-danger-800": "#930F31",
  "color-danger-900": "#7A0930",
  "color-danger-transparent-100": "rgba(255, 48, 48, 0.08)",
  "color-danger-transparent-200": "rgba(255, 48, 48, 0.16)",
  "color-danger-transparent-300": "rgba(255, 48, 48, 0.24)",
  "color-danger-transparent-400": "rgba(255, 48, 48, 0.32)",
  "color-danger-transparent-500": "rgba(255, 48, 48, 0.4)",
  "color-danger-transparent-600": "rgba(255, 48, 48, 0.48)"
};

const color_array = [
  '#00cec9',
  '#fd79a8',
  '#55efc4',
  '#e17055',
  '#0984e3',
  '#d63031',
  '#81ecec',
  '#74b9ff',
  '#a29bfe',
  '#ff7675',
  '#dfe6e9',
  '#00b894',
  '#6c5ce7',
  '#b2bec3',
  '#5f27cd',
  '#fab1a0',
  '#636e72',
  '#fdcb6e',
  '#e84393',
  '#2d3436',
  '#00d2d3',
  '#222f3e',
  '#48dbfb',
  '#10ac84',
  '#ee5253',
  '#c8d6e5',
  '#2e86de',
]

module.exports = { scheme, color_array };