import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_EXPENSE,
    DELETE_EXPENSE,
    EXPENSE_FORM_TOOGLE_LOADING,
    SET_EXPENSE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_EXPENSE,
} from "../constants";
import { formatExpenses } from "./settingsActionsUtils";

/* EXPENSE LIST */
export const fetchExpenses = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.expenses.list)
            .then((response) => {
                const expenses = formatExpenses(response.data);
                dispatch({
                    type: SET_EXPENSE_LIST,
                    payload: keyBy(expenses, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// EXPENSES
export const createExpense = (newExpense) => {
    return async (dispatch) => {
        dispatch({ type: EXPENSE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.expenses.create, newExpense)
            .then((response) => {
                const expense = formatExpenses(response.data);
                dispatch({ type: ADD_NEW_EXPENSE, payload: expense });
                dispatch({ type: EXPENSE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Gasto creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EXPENSE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateExpense = (updatedExpense) => {
    return async (dispatch) => {
        dispatch({ type: EXPENSE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.expenses.edit}${updatedExpense && updatedExpense._id}`, updatedExpense)
            .then((response) => {
                const expense = formatExpenses(response.data);
                dispatch({ type: UPDATE_EXPENSE, payload: expense });
                dispatch({ type: EXPENSE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Gasto actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EXPENSE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteExpenses = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: EXPENSE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.expenses.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_EXPENSE, payload: Ids });
                dispatch({ type: EXPENSE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Gasto eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EXPENSE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
